
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import CarrierLookupDetailsTable from '@/components/pages/services/carrierLookup/shared/CarrierLookupDetailsTable.vue'
import TmDropdownDownload from '@/components/shared/dropdowns/TmDropdownDownload.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  name: 'CarrierLookupHistoryDetailsModal',
  components: { TmButton, TmDropdownDownload, CarrierLookupDetailsTable, TmModal },
})
